import { Token } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Profile } from './class/class';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private profileSubject = new BehaviorSubject<Profile>({user : '', 
                                                         name  : '',
                                                         IsDiqAdmin : false,
                                                         Role : '',
                                                         token : ''}); 
  profileSubject$ = this.profileSubject.asObservable();

  updateData(newProfile: Profile) {
    this.profileSubject.next(newProfile);
  }

  //private token : string | null = null;
  //private hasAdminRole : boolean = false;
  //private profile!: Profile; 

  constructor() {}

  //setToken(token: string): void {
  //  this.token = token;
  //}

  //getToken(): string | null {
  // return this.token;
  //}

  //setRole(hasAdminRights : boolean) : void {
  //  this.hasAdminRole = hasAdminRights; 
 // }

 // getRole() : boolean {
 //   return this.hasAdminRole;
 // }

 // setProfile(profile : Profile) : void {
 //   this.profile = profile;
 // }

 // getProfile() : Profile {
 //   return this.profile;
 // }

}
