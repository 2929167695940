import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from './environments/environment';

export function MSALInstanceFactory(): IPublicClientApplication {
  
    return new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: 'https://login.microsoftonline.com/12beff2e-11d9-4a1f-8112-150c902e7393',
        redirectUri: environment.url
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    });
  }
  
  export const MSAL_INSTANCE = MSALInstanceFactory();
  

  //78347266-c8b1-49e5-af26-8167adc29306
  //3df7feb1-a99e-4bf6-b9fe-abe118672f6d -- original