import { DiqCarrierKeyValidation } from './../Interface/interface';
export class SearchBy {
    constructor(
      public searchBy: string,
      public searchFor: string      
    ) {}
}

export class EmployerSearch {
  constructor(
    public companyName: string,   
  ) {}
}

export class DiqClientSearch {
  constructor(
    public companyName: string,   
  ) {}
}


export class employer_Short {
  constructor(
    public Id : number,
    public FullName : string,
    public CleanName : string,
    public StreetName : string,
    public Address2 : string,
    public City : string,
    public State : string,
    public ZipCode : string,
    public CountryCode : string   
  ) {}
 
}

export class DiqCarrierKeyValidationParams {
  constructor(
    public companyId : number,
    public diqCode : string
  ) {}


}
  
  export class Profile
  {
    constructor(
      public user : string, 
      public name : string,
      public IsDiqAdmin : boolean, 
      public Role : string,
      public token : string 
    ) {}
       
  } 


