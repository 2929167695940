import { AuthenticationToken, DiqClient_short, DriverIqEsupervision, DiqCarrierKeyValidation } from './Interface/interface';
import { employer_Short } from './class/class';
import { EnvironmentInjector, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pipe } from 'rxjs';
import { environment } from '../environments/environment';
import { SearchBy, EmployerSearch } from './class/class';
import { Profile } from './class/class';
@Injectable({
  providedIn: 'root'
})
export class DriveriqWebService{

  private apiUrl = environment.apiUrl;
  authentication_url = "https://login.microsoftonline.com/" +  environment.tenantId + "/oauth2/token"; 
  
  token = ""; 
  TokenObject! : AuthenticationToken;
  diqServiceData : DriverIqEsupervision[] = [] 

  constructor(private http: HttpClient) {} 

  getAccessToken = (token : string) : Observable<AuthenticationToken> =>
  {       
    
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'response-Type' : 'json',
      });
        
       
        return this.http.post(this.authentication_url, {headers}
                                        ) as Observable<AuthenticationToken>;
  } 
  
  

  getDriverIq_CarriersESupervisionActive = () : Observable<DriverIqEsupervision[]> =>
  {               
  
    const headers = new HttpHeaders({
      'responseType' : 'json'           
    });

   
      return this.http.post(`${this.apiUrl}/DriverIqCarrier_WithActiveSupervision`, {headers}
    ) as Observable<DriverIqEsupervision[]>;
 }    
 
 
  DriverIqCarrier_GetBy = (searchBy : any) : Observable<DriverIqEsupervision[]> =>
    {
      /* in preparation for token
        let queryParams = new HttpParams();
        queryParams.append('Bearer', this.token);
      */

    let httpParams = new HttpParams();
    for (const key in searchBy) {
      if (searchBy.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, searchBy[key]);
      }
    }

      return this.http.get(`${this.apiUrl}/DriverIqCarrier_GetBySearchBy`, {
                                          responseType :'json',
                                          params : httpParams
                                        }
                                      ) as Observable<DriverIqEsupervision[]>;
  }

  DriverIq_Carriers_Update = (driverIq : DriverIqEsupervision) : Observable<DriverIqEsupervision[]> =>
    {     
     
        return this.http.post(`${this.apiUrl}/DriverIqCarrier_UpdateById`, JSON.stringify(driverIq), {
          responseType :'json'                                         
        }
      ) as Observable<DriverIqEsupervision[]>;
   }  

   DriverIq_Carriers_Insert = (driverIq : DriverIqEsupervision) : Observable<DriverIqEsupervision> =>
    {     
     
        return this.http.post(`${this.apiUrl}/DriverIqCarrier_Insert`, JSON.stringify(driverIq), {
          responseType :'json'                                         
        }
      ) as Observable<DriverIqEsupervision>;
   }  
  
   Employer_GetBySearchValue = (employerSearch : any) : Observable<employer_Short[]> =>
    {
      /* in preparation for token
        let queryParams = new HttpParams();
        queryParams.append('Bearer', this.token);
      */

      let httpParams = new HttpParams();
      for (const key in employerSearch) {
        if (employerSearch.hasOwnProperty(key)) {
          httpParams = httpParams.set(key, employerSearch[key]);
        }
      }
    
      return this.http.get(`${this.apiUrl}/Employers_GetByCompanyName`, {
        responseType :'json',
        params : httpParams
      }
    ) as Observable<employer_Short[]>;
  }

  DiqClient_GetBySearchValue = (diqSearch : any) : Observable<DiqClient_short[]> =>
    {
      /* in preparation for token
        let queryParams = new HttpParams();
        queryParams.append('Bearer', this.token);
      */

      let httpParams = new HttpParams();
      for (const key in diqSearch) {
        if (diqSearch.hasOwnProperty(key)) {
          httpParams = httpParams.set(key, diqSearch[key]);
        }
      }
    
      return this.http.get(`${this.apiUrl}/DriverIqGetByCompanyNameOrCode`, {
        responseType :'json',
        params : httpParams
      }
    ) as Observable<DiqClient_short[]>;
  }


  DriverIQCarrerKeyValidation = (diqCarrierKey : any) : Observable<DiqCarrierKeyValidation> =>
    {
      /* in preparation for token
        let queryParams = new HttpParams();
        queryParams.append('Bearer', this.token);
      */

      let httpParams = new HttpParams();
      for (const key in diqCarrierKey) {
        if (diqCarrierKey.hasOwnProperty(key)) {
          httpParams = httpParams.set(key, diqCarrierKey[key]);
        }
      } 
      return this.http.get(`${this.apiUrl}/DriverIqCarrierValidateKey`, {      
        responseType :'json',
        params : httpParams
      }
    ) as Observable<DiqCarrierKeyValidation>;
  }  

  CheckUser = () : Observable<Profile> => {
    return this.http.post(`${this.apiUrl}/CheckUserGroup`, {
      responseType : 'json'
    }
    ) as Observable<Profile>    
  } 

} 